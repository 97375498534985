import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// import { FaFacebookF, FaGoogle } from 'react-icons/fa';

// import { OldSocialLogin as SocialLogin } from 'react-social-login'

import api from '../../../services/api';

// import { signInMidiaSocial } from '../../../store/modules/auth/actions';

import SEO from '../../seo';

import { View, LoginView } from './styles';

export default function Cadastro() {
  const profile = useSelector(state => state.user.profile);
  // const dispatch = useDispatch();

  if (profile) {
    navigate('app/editar');
  }
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: '',
    name: '',
    password: '',
    repeatPassword: '',
  });

  async function handleSubmit(e) {
    e.preventDefault();

    const { name, email, password, repeatPassword } = form;

    setLoading(true);
    try {
      const { data } = await api.post('users', {
        name,
        email,
        password,
        repeatPassword
      });

      if (data.error) {
        toast.error(data.error);
        setLoading(false);
        return;
      }

      toast.success('Login criado com sucesso.');
      setLoading(false);

      navigate('login');
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
      setLoading(false);
    }
  }

  // const handleSocialLogin = async (usuario, err) => {
  //   const nameProvider = usuario._provider;
  //   const provider = usuario._profile.id;
  //   const { name, email } = usuario._profile;

  //   dispatch(signInMidiaSocial(nameProvider, provider, name, email));
  //   console.log(err); // eslint-disable-line
  // }

  return (
    <>
      <SEO title="Cadastro" />
      <View>
        <LoginView>
          <form
            method="post"
            onSubmit={e => handleSubmit(e)}
          >
            <h3>Cadastrar minha Conta</h3>

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              value={form.email}
              onChange={e => setForm({ ...form, email: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              name="name"
              value={form.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              type="password"
              label="Senha"
              variant="outlined"
              name="password"
              value={form.password}
              onChange={e => setForm({ ...form, password: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              type="password"
              label="Repete a senha"
              variant="outlined"
              name="repeatPassword"
              value={form.repeatPassword}
              onChange={e => setForm({ ...form, repeatPassword: e.target.value })}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading ? (
                'Carregando...'
              ) : (
                'Cadastrar Conta'
              )}
            </Button>

          </form>

          {/* <div className="redessociais">
            <SocialLogin
              provider='facebook'
              appId='1016895062083673'
              callback={handleSocialLogin}
            >
              <button
                type="button"
                className="facebook"
              >
               <FaFacebookF size={14} color="#fff" /> Login Via Facebook
              </button>
            </SocialLogin>

            <SocialLogin
              provider='google'
              appId='776444467681-qpec80e8ilc7toe7hal4mnedda33jqo6.apps.googleusercontent.com'
              callback={handleSocialLogin}
            >
              <button
                type="button"
                className="google"
              >
               <FaGoogle size={14} color="#fff" /> Login Via Google
              </button>
            </SocialLogin>
          </div> */}

          <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => navigate('/login')}
            >
              Voltar
            </Button>
        </LoginView>
      </View>
    </>
  );
}
