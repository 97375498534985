import React from 'react';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import Header from '../components/Header';
import Cadastro from '../components/pages/Cadastro';

import { store, persistor } from '../store';

import { View, Container } from '../components/pages/styles';

export default function Index() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
            <SEO title="Cadastro" />
            <Header />
            <View>
              <Container>
                <Cadastro />
              </Container>
            </View>

            <ToastContainer autoClose={3000} />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
