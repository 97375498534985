import styled from 'styled-components';

export const View = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
`;

export const LoginView = styled.div`
  display: flex;
  flex: 1;
  max-width: 680px;
  padding-left: 8px;
  padding-right: 8px;
  flex-direction: column;

  .radio {
    display: flex;
  }

  .btnNext {
    display: flex;
    flex-direction: column;

    button + button {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .bloco {
    display: flex;
    flex-direction: column;
    background: #fff;
    text-align: center;
    border-top: 1px dotted #ccc;

    .conteudo-form {
      display: flex;
      flex-direction: column;
      padding-top: 14px;
    }

    .btn {
      background: #F0FFF4;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 28px;
    }
  }

  .redessociais {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 12px;
    padding-top: 12px;

    div {
      width: 100%;
    }

    div + div {
      margin-left: 4px;
    }

    .google {
      width: 100%;
      border: 0;
      background: #E96358;
      padding: 12px 8px;
      border-radius: 4px;
      color: #fff;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: #E44336;
      }
    }

    .facebook {
      width: 100%;
      border: 0;
      background: #006FDD;
      color: #fff;
      padding: 12px 8px;
      border-radius: 4px;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: #178BFF;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .form {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex: 1;

      div {
        width: 100%;
      }

      div + div {
        margin-top: 0px;
        margin-left: 4px;
      }
    }

    input {
      width: 100%;
      background: #fff;
    }

    div + div {
      margin-top: 8px;
    }

    button {
      margin-top: 8px;
    }

    .separa {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }

    h3 {
      display: flex;
      justify-content: center;
      margin-bottom: 14px;
      color: #999;
    }
  }
`;
